// This file has been deprecated as we no longer use Posthog
export const useAnalytics = () => {
    const captureEvent = (eventName: string, properties?: Record<string, any>) => {
        // No-op
    }

    const pageView = (pageName?: string | Record<string, any>, properties?: Record<string, any>) => {
        // No-op
    }

    const identify = (userId: string, properties?: Record<string, any>) => {
        // No-op
    }

    const reset = () => {
        // No-op
    }

    const formSubmitted = (formName: string, properties?: Record<string, any>) => {
        // No-op
    }

    // Alias methods for backward compatibility
    const trackEvent = captureEvent;
    const trackPageView = (pageName: string, properties?: Record<string, any>) => pageView(pageName, properties);
    const identifyUser = identify;
    const resetUser = reset;
    const trackFormSubmission = formSubmitted;

    return {
        captureEvent,
        pageView,
        identify,
        reset,
        formSubmitted,
        // Backward compatibility exports
        trackEvent,
        trackPageView,
        identifyUser,
        resetUser,
        trackFormSubmission,
    }
}
